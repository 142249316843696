/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateStatus = /* GraphQL */ `
  mutation UpdateStatus($thorId: ID!, $status: actionLevel!) {
    updateStatus(thorId: $thorId, status: $status) {
      id
      name
      module
      parent
      message
      timestamp
      state
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteThor = /* GraphQL */ `
  mutation DeleteThor(
    $input: DeleteThorInput!
    $condition: ModelthorConditionInput
  ) {
    deleteThor(input: $input, condition: $condition) {
      id
      name
      module
      parent
      message
      timestamp
      state
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const createThor = /* GraphQL */ `
  mutation CreateThor(
    $input: CreateThorInput!
    $condition: ModelthorConditionInput
  ) {
    createThor(input: $input, condition: $condition) {
      id
      name
      module
      parent
      message
      timestamp
      state
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateThor = /* GraphQL */ `
  mutation UpdateThor(
    $input: UpdateThorInput!
    $condition: ModelthorConditionInput
  ) {
    updateThor(input: $input, condition: $condition) {
      id
      name
      module
      parent
      message
      timestamp
      state
      code
      status
      createdAt
      updatedAt
    }
  }
`;
