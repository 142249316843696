/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToUpdateStatus = /* GraphQL */ `
  subscription SubscribeToUpdateStatus($thorId: ID, $status: actionLevel) {
    subscribeToUpdateStatus(thorId: $thorId, status: $status) {
      id
      name
      module
      parent
      message
      timestamp
      state
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThor = /* GraphQL */ `
  subscription OnCreateThor {
    onCreateThor {
      id
      name
      module
      parent
      message
      timestamp
      state
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThor = /* GraphQL */ `
  subscription OnUpdateThor {
    onUpdateThor {
      id
      name
      module
      parent
      message
      timestamp
      state
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThor = /* GraphQL */ `
  subscription OnDeleteThor {
    onDeleteThor {
      id
      name
      module
      parent
      message
      timestamp
      state
      code
      status
      createdAt
      updatedAt
    }
  }
`;
