/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStatusCount = /* GraphQL */ `
  query GetStatusCount($status: actionLevel!) {
    getStatusCount(status: $status) {
      status
      count
    }
  }
`;
export const getStatusList = /* GraphQL */ `
  query GetStatusList($status: actionLevel!, $parent: String) {
    getStatusList(status: $status, parent: $parent) {
      items {
        id
        name
        module
        parent
        message
        timestamp
        state
        code
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThor = /* GraphQL */ `
  query GetThor($id: ID!) {
    getThor(id: $id) {
      id
      name
      module
      parent
      message
      timestamp
      state
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const listThors = /* GraphQL */ `
  query ListThors(
    $filter: ModelthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        module
        parent
        message
        timestamp
        state
        code
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
