import React, { useEffect, useState, useCallback, Fragment } from "react";
import { API, Auth, Hub } from "aws-amplify";
import { updateThor, updateStatus } from "./graphql/mutations";
import { getStatusList, getStatusCount } from "./graphql/queries";
import { onCreateThor, onUpdateThor } from "./graphql/subscriptions";

/*   
  id: ID!
  name: String!
  module: String!
  parent: String
  message: String
  timestamp: AWSDateTime
  state: String
  code: Int
  status: actionLevel!
 */

const ActionLevelEnum = {
  ERROR: "ERROR",
  INPROGRESS: "INPROGRESS",
  RESOLVED: "RESOLVED",
};

const App = () => {
  const [items, setItems] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("-1");
  const [errorThors, setErrorThors] = useState([]);
  const [inprogressThors, setInprogressThors] = useState([]);
  const [inprogressCount, setInprogressCount] = useState(0);
  const [resolvedThors, setResolvedThors] = useState([]);
  const [resolvedCount, setResolvedCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [user, setUser] = useState(null);

  const onPageRendered = useCallback(async () => {
    var errorThors = await fetchThors(ActionLevelEnum.ERROR);
    var inprogressThors = await fetchThors(ActionLevelEnum.INPROGRESS);
    var resolvedThors = await fetchThors(ActionLevelEnum.RESOLVED);
    setErrorThors(errorThors);
    setItems(getLabels([...errorThors, ...inprogressThors]));

    setInprogressThors(inprogressThors);
    setResolvedThors(resolvedThors);
    subscribeErrorStateChanged();
    subscribeCreatedThors();
    getStatusCounts();
  }, []);

  useEffect(() => {
    console.log("USER");
    console.log(user);
    if (user != null || user != undefined) {
      console.log("triggered");
      onPageRendered();
    }
  }, [onPageRendered, user]);

  function getLabels(thors) {
    // setItems(body.results.map(({ name }) => ({ label: name, value: name })));
    var allThors = [...new Set(thors.map((data) => data.parent))];

    function myFunction(value, index) {
      return { value: value, label: index };
    }

    return allThors.map(myFunction);

    // return allThors.map(({ name }) => ({ "label": name, "value": name }));
  }

  // ################### COGNITO ###############################

  const login = () => {
    Auth.federatedSignIn();
  };

  const logout = () => {
    Auth.signOut();
  };

  const logUser = () => {
    console.log(user);
  };

  useEffect(() => {
    getUser().then((userData) => setUser(userData));
  }, []);

  const getUser = () => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  };

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
  }, []);

  // #####################################################################

  const subscribeCreatedThors = async () => {
    console.log("subscription createdThors added");
    await API.graphql({
      query: onCreateThor,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).subscribe({
      next: (subOnUpdate) => {
        console.log("next called");
        console.log(JSON.stringify(subOnUpdate, null, 2));
        console.log(subOnUpdate.value.data.onCreateThor.id);
        const statusValue = subOnUpdate.value.data.onCreateThor.status;
        console.log(statusValue);
        if (statusValue === ActionLevelEnum.ERROR) {
          setErrorThors((errorThors) => [
            ...errorThors,
            subOnUpdate.value.data.onCreateThor,
          ]);
          setErrorCount((errorCount) => errorCount + 1);
        } else {
          alert("ERROR");
        }
      },
    });
  };

  const subscribeErrorStateChanged = async () => {
    console.log("subscription added");
    await API.graphql({
      query: onUpdateThor,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).subscribe({
      next: (subOnUpdate) => {
        console.log("next called");
        console.log(JSON.stringify(subOnUpdate, null, 2));
        console.log(subOnUpdate.value.data.onUpdateThor.id);
        const statusValue = subOnUpdate.value.data.onUpdateThor.status;
        console.log(statusValue);
        if (statusValue === ActionLevelEnum.INPROGRESS) {
          setInprogressThors((inprogressThors) => [
            ...inprogressThors,
            subOnUpdate.value.data.onUpdateThor,
          ]);
          setErrorThors((errorThors) =>
            errorThors.filter(
              (i) => i.id !== subOnUpdate.value.data.onUpdateThor.id
            )
          );
          setInprogressCount((inprogressCount) => inprogressCount + 1);
          setErrorCount((errorCount) => errorCount - 1);
        } else if (statusValue === ActionLevelEnum.RESOLVED) {
          setInprogressThors((inprogressThors) =>
            inprogressThors.filter(
              (i) => i.id !== subOnUpdate.value.data.onUpdateThor.id
            )
          );
          setInprogressCount((inprogressCount) => inprogressCount - 1);
          // alert(
          //   "Thor status updates : " + subOnUpdate.value.data.onUpdateThor.id + " with status "+ subOnUpdate.value.data.onUpdateThor.status
          // );
        } else {
          setErrorThors((thors) => [
            ...thors,
            subOnUpdate.value.data.onUpdateThor,
          ]);
          setErrorCount((thorCount) => thorCount + 1);
          setResolvedThors((thors) =>
            thors.filter((i) => i.id !== subOnUpdate.value.data.onUpdateThor.id)
          );
          setResolvedCount((thorCount) => thorCount - 1);
          // alert("ERROR");
          //  block of code to be executed if the condition1 is false and condition2 is false
        }
      },
    });
  };

  async function getStatusCounts() {
    try {
      const thorData = await API.graphql({
        query: getStatusCount,
        variables: { status: ActionLevelEnum.ERROR },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      console.log(thorData);

      const errorThors = thorData.data.getStatusCount.count;
      setErrorCount(errorThors);

      const inprogressCount = await API.graphql({
        query: getStatusCount,
        variables: { status: ActionLevelEnum.INPROGRESS },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      setInprogressCount(inprogressCount.data.getStatusCount.count);

      const resolvedCount = await API.graphql({
        query: getStatusCount,
        variables: { status: ActionLevelEnum.RESOLVED },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      setResolvedCount(resolvedCount.data.getStatusCount.count);
    } catch (err) {
      console.log(err);
      console.log("error fetching count");
    }
  }

  async function fetchThors(statusValue, sortkey) {
    var thorData = [];
    console.log(statusValue);
    try {
      if (typeof sortkey === "string") {
        thorData = await API.graphql({
          query: getStatusList,
          variables: {
            status: statusValue,
            parent: sortkey,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
      } else {
        console.log(1);

        thorData = await API.graphql({
          query: getStatusList,
          variables: { status: statusValue },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        console.log(2);
      }
    } catch (error) {
      console.log(error);
    }

    console.log(thorData.length);
    const errorThors = thorData.data.getStatusList.items;
    return errorThors;
  }

  async function updateThorStatus(thorid, status, timestamp, parent) {
    try {
      var newstatus = "";
      console.log(thorid);
      console.log(status);
      if (status === "ERROR") {
        // console.log('status="ERROR"')
        newstatus = ActionLevelEnum.INPROGRESS;
      } else if (status === "INPROGRESS") {
        // console.log('status="INPROGRESS"')
        newstatus = ActionLevelEnum.RESOLVED;
      } else {
        // console.log('status="RESOLVED"')
        newstatus = ActionLevelEnum.ERROR;
      }

      await API.graphql({
        query: updateStatus,
        variables: { thorId: thorid, status: newstatus },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("Works so far");

      await API.graphql({
        query: updateThor,
        variables: {
          input: {
            id: thorid,
            status: newstatus,
            timestamp: timestamp,
            parent: parent,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      console.log("Still works");

      console.log("thor updated");
      // fetchErrorThors();
      console.log("errors fetched");
      // fetchInProgressThors();
      console.log("inprogress fetched");
    } catch (err) {
      console.log(err);
      console.log("error updating thors");
    }
  }

  const CountComponent = ({ count }) => {
    return (
      <div style={styles.column}>
        <h1>{count}</h1>
      </div>
    );
  };
  async function onSelectChange(event) {
    console.log("not -1");

    var selectElement = event.target;
    // (e)=>{this.setState({value: e.target.value})
    var value = selectElement.value;
    setSelectedLabel(value);
    const thorData = await fetchThors(ActionLevelEnum.INPROGRESS, value);
    // console.log(JSON.stringify(thorData, null, 2));
    setInprogressThors(thorData);
    const errorData = await fetchThors(ActionLevelEnum.ERROR, value);
    setErrorThors(errorData);

    const resolvedData = await fetchThors(ActionLevelEnum.RESOLVED, value);
    setResolvedThors(resolvedData);
  }

  async function onResetFilterClick() {
    console.log("reset to -1");
    setSelectedLabel("-1");
    const thorData = await fetchThors(ActionLevelEnum.INPROGRESS);
    // console.log(JSON.stringify(thorData, null, 2));
    setInprogressThors(thorData);
    const errorData = await fetchThors(ActionLevelEnum.ERROR);
    setErrorThors(errorData);
    const resolvedData = await fetchThors(ActionLevelEnum.RESOLVED);
    setResolvedThors(resolvedData);
  }

  function CharacterDropDown({ items }) {
    const divStyle = {
      display: "flex",
      alignItems: "center",
    };
    return (
      <div style={divStyle}>
        <select
          style={styles.selectLabel}
          value={selectedLabel}
          onChange={(event) => onSelectChange(event)}
        >
          <option value="-1" disabled>
            Select
          </option>
          {items.map(({ label, value }) => (
            <option key={value} value={value}>
              {label} {value}
            </option>
          ))}
        </select>
        <button style={styles.resetButton} onClick={() => onResetFilterClick()}>
          RESET FILTERS
        </button>
      </div>
    );
  }

  const ListComponent = ({ thors }) => {
    return (
      <div style={styles.column}>
        {thors.map((thor) => (
          <div key={thor.id} style={styles.todo}>
            <p style={styles.todoName}>{thor.name}</p>
            <p style={styles.todoMessage}>{thor.message}</p>
            <p style={styles.thorStatus}>{thor.status}</p>
            <p style={styles.thorTimestamp}>{thor.timestamp}</p>
            <button
              onClick={() =>
                updateThorStatus(
                  thor.id,
                  thor.status,
                  thor.timestamp,
                  thor.parent
                )
              }
            >
              UPDATE
            </button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      {!user && (
        <div>
          <h2>Welcome to the new improved thorbjørn - please log in</h2>
          <button onClick={login}>Login</button>
        </div>
      )}
      {user && (
        <div>
          <button onClick={logout}>Logout</button>
        </div>
      )}
      {user && (
        <div>
          <CharacterDropDown items={items} />
          <div style={styles.row}>
            <CountComponent count={errorCount} />
            <CountComponent count={inprogressCount} />
            <CountComponent count={resolvedCount} />
          </div>
          <div style={styles.row}>
            <ListComponent thors={errorThors} />
            <ListComponent thors={inprogressThors} />
            <ListComponent thors={resolvedThors} />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const styles = {
  container: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  row: { display: "flex" },
  column: { flex: 0.5, padding: 10 },
  todo: { marginBottom: 15 },
  input: {
    border: "none",
    backgroundColor: "#ddd",
    marginBottom: 10,
    padding: 8,
    fontSize: 18,
  },
  todoName: { fontSize: 20, fontWeight: "bold" },
  todoMessage: { marginBottom: 0 },
  thorTimestamp: { marginBottom: 0 },
  thorStatus: { fontSize: 16, fontWeight: "bold" },
  button: {
    backgroundColor: "black",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
  },
  selectLabel: {
    display: "flex",
    flex: 1,
    fontSize: 16,
    fontFamily: "sans-serif",
    fontWeight: 700,
    color: "#444",
    lineHeight: 1.3,
    padding: ".6em 1.4em .5em .8em",
    width: "80%",
    maxWidth: "100",
    boxSizing: "border-box",
    margin: 0,
    border: "1px solid #aaa",
    boxShadow: "0 1px 0 1px rgba(0,0,0,.04)",
    borderRadius: ".5em",
    backgroundColor: "#fff",
    // backgroundImage: `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    //   linearGradient(to bottom, #ffffff 0%,#e5e5e5 100%)`,
    // backgroundRepeat: "no-repeat, repeat",
    // backgroundPosition: "right .7em top 50%, 0 0",
    // backgroundSize: ".65em auto, 100%"
  },
  resetButton: {
    padding: ".6em 1.4em .5em .8em",
    fontSize: 16,
    fontFamily: "sans-serif",
    fontWeight: 700,
    color: "#444",
  },
};

export default App;
