import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";

const {
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_WEB_CLIENT_ID,
  REACT_APP_REDIRECT_SIGN_IN,
  REACT_APP_REDIRECT_SIGN_OUT,
  REACT_APP_GRAPHQL,
} = process.env;

const amplifyConf = {
  aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL,
  aws_project_region: "eu-west-1",
  Auth: {
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_WEB_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: "authentication.auth.eu-west-1.amazoncognito.com",
      scope: ["openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${window.location.origin}`,
      redirectSignOut: REACT_APP_REDIRECT_SIGN_OUT,
      responseType: "token",
    },
  },
};

Amplify.configure(amplifyConf);

ReactDOM.render(
  <React.StrictMode>
    {console.log(window.location.hostname)}
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
